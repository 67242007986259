<template>
  <div>
    <div class="router-header">
      <ul>
        <li
          v-for="(item, index) in filteredTabs"
          :key="index"
          :class="{ active: item.routeName === currentPage }"
          @click="redirect(item.path)"
        >
          {{ item.tabName }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["tabs"],

  computed: {
    currentPage() {
      return this.$route.name;
    },
    filteredTabs() {
      const tabs = this.tabs;
      return tabs.filter((tab) => {
        return this.hasResource(tab.access);
      });
    },
  },
  methods: {
    redirect(path) {
      this.$router.push(path);
    },
    init() {
      let length = this.$route.path.split('/').length;
     if (/^\/?operations\/?$/i.test(this.$route.path)) {
            const firstPage = this.filteredTabs[0].path;
            this.$router.replace(firstPage);
        } else if(length=== 2){  //暂时补丁， 路由只要是以 ‘/xxxx’形式的，则全部跳转到首个Tabs
            const firstPage = this.filteredTabs[0].path;
            this.$router.push(firstPage);
        }
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    $route(to, from) {
      if (to.matched.length <= 2) {
        const firstPage = this.filteredTabs[0].path;
        this.$router.push(firstPage);
      }
    }
  },

};
</script>

<style lang="less" scoped>
.router-header {
  ul {
    display: flex;
    li {
      margin-top: 66px;
      margin-right: 99px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #9b9b9b;
      cursor: pointer;
    }
    .active {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      color: #000000;
    }
  }
}
</style>
